<template>
    <ion-page>
        <Header></Header>
        <ion-content class="ion-padding" fullscreen>
            <div v-if="isValid" class="ion-padding canvas-container">
                <canvas id="canvas"></canvas>
            </div>
            <div v-if="notValid" class="danger-notice">
                <span>Der eingescannte Käse ist Ihnen nicht zugewiesen. Bitte wenden Sie sich an das AMA-Organisationsteam</span>
            </div>
            <ion-button class="bottom ion-margin" expand="block" @click="dismiss">Abbrechen</ion-button>
        </ion-content>
    </ion-page>
</template>

<script>
import jsQR from "jsqr";
import {
    IonContent,
    IonButton,
    IonPage,
    modalController,
    toastController
} from '@ionic/vue';
import Header from "@/components/Header";
import {defineComponent} from 'vue';
import {currentContest} from "@/util/ContestService";
import {loggedInJuror} from "@/util/JuryService";
export default defineComponent({
    name: "QR",
    components: {
        IonContent, Header, IonPage, IonButton
    },
    data() {
        return {
            canvasElement: null,
            canvas: null,
            video: null,
            notValid: false,
        }
    },
    methods: {
        async openToast() {
            const toast = await toastController
                .create({
                    message: 'Your settings have been saved.',
                    duration: 10000,
                    position: 'bottom',
                    header: 'Fehler!',
                    color: 'danger'
                })
            return toast.present();
        },
        dismiss() {
            toastController.dismiss()
            if (this.isValid && this.video && this.video.srcObject) {
                this.video.srcObject.getTracks().forEach(function (track) {
                    track.stop();
                });
            }
            modalController.dismiss()
        },
        drawLine(begin, end, color) {
            this.canvas.beginPath();
            this.canvas.moveTo(begin.x, begin.y);
            this.canvas.lineTo(end.x, end.y);
            this.canvas.lineWidth = 4;
            this.canvas.strokeStyle = color;
            this.canvas.stroke();
        },
        tick() {
            if (this.video.readyState === this.video.HAVE_ENOUGH_DATA) {
                this.canvasElement.height = this.video.videoHeight;
                this.canvasElement.width = this.video.videoWidth;
                this.canvas.drawImage(this.video, 0, 0, this.canvasElement.width, this.canvasElement.height);
                const imageData = this.canvas.getImageData(0, 0, this.canvasElement.width, this.canvasElement.height);
                const code = jsQR(imageData.data, imageData.width, imageData.height, {
                    inversionAttempts: "dontInvert",
                });
                if (code) {
                    this.drawLine(code.location.topLeftCorner, code.location.topRightCorner, "#FF3B58");
                    this.drawLine(code.location.topRightCorner, code.location.bottomRightCorner, "#FF3B58");
                    this.drawLine(code.location.bottomRightCorner, code.location.bottomLeftCorner, "#FF3B58");
                    this.drawLine(code.location.bottomLeftCorner, code.location.topLeftCorner, "#FF3B58");
                    this.data = code

                    if(this.getCheeseInfos(code.data)){
                        if (currentContest.value && loggedInJuror.value) {
                            const tables = currentContest.value.getTables().filter(table => table.jurorIds.find(id => id.id === loggedInJuror.value.id));
                            tables.forEach((table) => {
                                table.plateList.forEach((plate) => {
                                    if(plate.cheese.id == this.getCheeseInfos(code.data).id){
                                        if(!this.getCheeseInfos(code.data).innovation){
                                            this.$router.push('/rating/cheese/' + this.getCheeseInfos(code.data).id + '?tableID=' + this.$route.params.tableID)
                                            this.dismiss()
                                        }else{
                                            this.$router.push('/rating/innovation/' + this.getCheeseInfos(code.data).id + '?tableID=' + this.$route.params.tableID)
                                            this.dismiss()
                                        }
                                    }else{
                                        this.notValid = true;
                                    }
                                })
                            });
                        }


                    }

                }
            }
            requestAnimationFrame(this.tick);
        },
        getCheeseInfos(id) {
            if (!isNaN(id)) {
                if (currentContest.value) {
                    return currentContest.value.getParticipant(Number(id))
                }
            }
            return null
        },
    },
    computed: {
        isValid() {
            if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
                return true
            }
            this.openToast()
            return false
        }
    },
    mounted() {
        if (this.isValid) {
            this.canvasElement = document.getElementById("canvas");
            this.video = document.createElement("video");
            this.canvas = this.canvasElement.getContext("2d");
            navigator.mediaDevices.getUserMedia({video: {facingMode: "environment"}}).then((stream => {
                this.video.srcObject = stream;
                this.video.setAttribute('autoplay', '');
                this.video.setAttribute('muted', '');
                this.video.setAttribute('playsinline', '');
                this.video.play();
                requestAnimationFrame(this.tick);
            }));
        }
    }
})
</script>
