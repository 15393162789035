<template>
    <IonToolbar slot="primary" color="primary">
        <ion-buttons slot="start">
            <ion-back-button v-if="back" slot="start" color="secondary" text="Zurück" defaultHref="/"></ion-back-button>
        </ion-buttons>
        <ion-title>{{ title }}</ion-title>
    </IonToolbar>
</template>

<script>
import {IonBackButton, IonToolbar,IonButtons, IonTitle} from '@ionic/vue';
export default {
    name: "Header",
    components: {
        IonToolbar,
        IonBackButton,
        IonButtons,
        IonTitle
    },
    props: {
        back: {
            type: Boolean,
            default: false
        },
        title: {
            type: String,
        }
    }
}
</script>
