
import {checkmarkCircleOutline, closeCircleOutline, helpCircleOutline} from 'ionicons/icons';
import {
    IonButton,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardTitle,
    IonCol,
    IonContent,
    IonGrid,
    IonPage,
    IonRow,
    IonIcon,
    onIonViewDidEnter, modalController,
} from '@ionic/vue';
import {defineComponent} from 'vue'
import Header from "@/components/Header.vue";
import {currentContest} from "@/util/ContestService";
import {loggedInJuror} from "@/util/JuryService";
import {fetchResultPoints} from "@/util/ResultService";
import {Plate} from "@/util/PlateService";
import {ResultPoints} from "@/util/ResultService";
import {getTableIndex, getTableTitle} from "@/util/TableService";
import {setLockedTable} from "@/util/LocalStateService";
import QR from "@/components/QR.vue";

export default defineComponent({
    name: "Rating",
    components: {
        Header, IonPage, IonGrid, IonRow, IonCol, IonContent,
        IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonButton,
        IonIcon
    },
    setup() {
        onIonViewDidEnter(() => {
            fetchResultPoints()
        });
        return {
            closeCircleOutline,
            checkmarkCircleOutline,
            helpCircleOutline
        }
    },
    data() {
        return {
            accepted: false
        }
    },
    methods: {
        goBack() {
            this.$router.push('/tables')
        },

        openRating(id: number, innovation: boolean) {
            if (!innovation) {
                this.$router.push('/rating/cheese/' + id + '?tableID=' + this.$route.params.tableID)
            } else {
                this.$router.push('/rating/innovation/' + id + '?tableID=' + this.$route.params.tableID)
            }
        },

        getResultState(plate: Plate): string|undefined {
            if (plate.cheese.resultPoints) {
                return (plate.cheese.resultPoints as ResultPoints).currentPlace
            }
            return undefined
        },

        async openQRModal(): Promise<any> {
            const modal = await modalController
                .create({
                    component: QR,
                    componentProps: {},
                    cssClass: 'custommodal'
                })
            return modal.present();
        },
        lockTable(){
            if(loggedInJuror?.value?.id && this.$route.params.tableID){
                setLockedTable(loggedInJuror.value.id, this.$route.params.tableID).then(() => {
                    this.$router.push('/tables')
                })
            }

        }
    },
    computed: {
        getJurorName(){
            if ( loggedInJuror.value) {
                return 'Juror: ' + loggedInJuror.value._firstName +' '+ loggedInJuror.value._lastName
            }
            return ''
        },
        getCountDonePlates(): number {
            let done = 0
            if (this.$route.params.tableID) {
                if (currentContest.value) {
                    const list = currentContest.value.getPlates(Number(this.$route.params.tableID))
                    for(let i = 0; list.length > i; i++ ){
                        if (list[i].cheese.resultPoints) {
                            (list[i].cheese.resultPoints as ResultPoints).currentPlace === 'finished' ? done++ : 0
                        }
                    }
                }
            }
            return done
        },
        getLoggedInJuror() {
            if (loggedInJuror.value) {
                return loggedInJuror.value.getName()
            } else return null
        },
        getCurrentTable() {
            return getTableTitle(this.$route.params.tableID);
        },
        getCurrentTableIndex() {
            return getTableIndex(this.$route.params.tableID)
        },
        getCheeseList() {
            if (this.$route.params.tableID) {
                if (currentContest.value) {
                    return currentContest.value.getPlates(Number(this.$route.params.tableID))
                }
            }
            return false
        },
    },
    mounted() {
        if (!this.$route.params.tableID || isNaN(Number(this.$route.params.tableID))) {
            this.$router.push('/')
        }
    }
})
